import React from "react";
import Layout from "../../../other/layout";
import FabricantsEtGrossistes from "../../../components/AC-StaticPages/VotreEntreprise/Subpages/FabricantsEtGrossistes";
import SEO from "../../../other/seo";

export default function fabricantsEtGrossistes() {
  return (
    <Layout>
      <SEO title="Fabricants et Grossistes" />
      <FabricantsEtGrossistes />
    </Layout>
  );
}
