import React from "react";
import { Link } from "gatsby";
import styles from "./styles.module.css";
import whole_products from "../../../../assets/img/whole_products.gif";
import getweb_lboard2 from "../../../../assets/img/getweb_lboard2.gif";
import whole_web_templates from "../../../../assets/img/whole_web_templates.gif";
import BNLGO93F from "../../../../assets/img/BNLGO93F.gif";
import EMFCB86F from "../../../../assets/img/EMFCB86F.gif";
import EMCQ191F from "../../../../assets/img/EMCQ191F.jpg";
import Button from "../../components/Button";

export default function FabricantsEtGrossistes() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Section Fabricants et Grossistes</h1>
        <p>
          Deluxe offre une vaste gamme de produits conçus pour les personnes
          oeuvrant dans les secteurs manufacturier et de la vente en gros :
          bordereaux d’expédition, connaissements, carnets de factures et
          chèques d’affaires, de même qu’un éventail complet d’imprimés
          promotionnels et d’articles publicitaires tels que cartes d’affaires,
          blocs-notes, tabliers, t-shirts et stylos qui feront en sorte que vos
          clients capteront votre message à coup sûr!
        </p>
      </div>
      <div className={styles.row}>
        <h2>
          Des produits conçus pour contribuer au secteur de fabricants et
          grossistes
        </h2>
        <Link to="/votre-entreprise/fabricants-et-grossistes/produits-pour-fabricants-et-grossistes/">
          <img src={whole_products} alt="Produits en gros"></img>
        </Link>
        <Button
          text={"Produits en gros D'Info"}
          url="/votre-entreprise/fabricants-et-grossistes/produits-pour-fabricants-et-grossistes/"
        />
      </div>
      <div className={styles.row}>
        <Link to="/developpez/services-web/solutions-getweb/">
          <img
            src={getweb_lboard2}
            alt="Besoin de cliquer avec plus clients? GetWeb c'est ici!"
          ></img>
        </Link>
        <Button
          text={"GetWeb D'Info"}
          url="/developpez/services-web/solutions-getweb/"
        />
      </div>
      <div className={styles.row}>
        <h2>
          Modèles de sites Web pour le secteur de fabricants et grossistes
        </h2>
        <Link to="/votre-entreprise/fabricants-et-grossistes/modles-de-sites-pour-fabricants-et-grossistes/">
          <img
            src={whole_web_templates}
            alt="Modèles de sites Web pour le secteur de fabricants et grossistes"
          ></img>
        </Link>
        <Button
          text={"Modèles de sites D'Info"}
          url="/votre-entreprise/fabricants-et-grossistes/modles-de-sites-pour-fabricants-et-grossistes/"
        />
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <Link to="/demarrez/conception-de-logo/">
            <img
              src={BNLGO93F}
              alt="Quelle est la première impression de vos client à propos de votre entreprise?"
            ></img>
          </Link>
        </div>
        <div className={styles.column}>
          <Link to="/developpez/articles-couleur/">
            <img
              src={EMFCB86F}
              alt="Rabais de 25% sur votre première commande d'imprimes promotionnels couleur!"
            ></img>
          </Link>
        </div>
        <div className={styles.column}>
          <Link to="/gerez/cheques/">
            <img
              src={EMCQ191F}
              alt="Achetez-vous encore vos chèques de la banque? Visez nos chèques et prenez le virage NEBS!"
            ></img>
          </Link>
        </div>
      </div>
    </div>
  );
}
